export default {
    admin: '체크인 관리자',
    menu: {
        dashboard: '대시보드',
        room: '룸 관리',
        room_list: '스터디 룸',
        room_all: '룸 목록',
        room_detail: '룸 상세',
        manager: '관리자 관리',
        change_password: '비밀번호 변경',
        log_out: '로그아웃',
    },
    modal: {
        alert: '알림',
        confirm: '확인',
        ok: '예',
        no: '아니요',
        cancel: '취소',
        close: '닫기',
        apply: '적용',
        save_confirm: '저장하시겠습니까?',
        delete_confirm: '삭제하시겠습니까?',
        save: '저장',
    },
    msg: {
        applied: '적용되었습니다.',
        saved: '저장되었습니다.',
        deleted: '삭제되었습니다.'
    },
    common: {
        save: '저장',
        delete: '삭제',
        edit: '수정',
        add: '추가',
        search: '검색',
        nickname: '닉네임',
        gender: '성별',
        male: '남자',
        female: '여자',
        manage: '관리',
        title: '제목',
        content: '내용',
        image: '이미지',
        status: '상태',
        create_date: '생성일',
        update_date: '수정일',
        download: '다운로드',
        view: '보기',
        list: '목록',
        type: 'type',
        total: '전체',
        year: '년',
        month: '월',
        date: '일',
        unknown: 'unknown',
        normal: 'normal',
        deleted: 'deleted',
        completed: 'completed',
        db_delete: 'DB에서 삭제',
        detail: '상세보기',
        mr: 'mr',
        on: 'ON',
        off: 'OFF',
    },
    search_option: {
        room: [
            { value: 'idx', name: '아이디' },
            { value: 'roomNo', name: '룸 번호' },
            { value: 'content', name: '가이드' },
        ],
    },
    login: {
        id: '아이디',
        password: '비밀번호',
        login: '로그인',
    },
    change_password: {
        current_password: '현재 비밀번호',
        new_password: '새 비밀번호',
        confirm_password: '비밀번호 확인',
        change: '변경',
        password_not_correct: '새 비밀번호와 비밀번호 확인이 동일하지 않습니다.',
    },
    room: {
        id: "아이디",
        room_no: "룸 번호",
        wifi: "Wifi 정보",
        guide: "이용가이드",
        other: "기타",
        image: "커버 이미지",
        add: "룸 생성",
        detail: "룸 상세",
        main_info: "기본 정보",
        room_member: "룸 가입자",
        room_id: "룸 아이디",
        sender: "송신자",
        receiver: "수신자",
        chat_type: "유형",
        content: "내용",
        read: "읽음 상태",
        member_name: "가입자명",
    },
    dashboard: {
        today: '오늘',
        yesterday: '어제',
        this_week: '이번주',
        this_month: '이번달',
        this_year: '올해',
        sales: 'sales',
        signup_count: '가입자 수',
        signup_ratio: '가입율',
        login_chart_title: '로그인',
        signup_chart_title: '회원가입',
        just_go: '바로가기',
        total: '전체',
        _y: "년",
        _m: "월",
        _d: "일",
        coming_soon: "준비중 입니다",
        _Times: "회",
        total: "Total",
        check_out: "확인하기",
    },
    toast: {
        select_start_date: "시작일을 선택하세요",
        select_end_date: "종료일을 선택하세요",
    }
}
