import { useContext, useRef } from "react";
import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import DataTable from "../../../components/control/data-table";
import $ from "jquery";

const RoomDetailMember = ({ room_id }) => {

  const context = useContext(PageSettings);

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const onCell = user_id => {
    context.showUserPopup(user_id, () => refreshTable(false));
  };

  return (
    <div>
      <div className='row'>
        <div className='col-md-12 mt-3'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [
                {
                  targets: [columnNo++], data: 'name', orderable: false,
                  render: function (value) {
                    return value;
                  }
                },
                {
                  targets: [columnNo++], data: 'job', orderable: false,
                },
                {
                  targets: [columnNo++], data: 'address', orderable: false,
                },
                {
                  targets: [columnNo++], data: 'phone', orderable: false,
                },
                {
                  targets: [columnNo++], data: 'photo', orderable: false,
                  createdCell: (td, value) => {
                    if (value) {
                      $(td).image_lightbox(value, () => {
                        context.showGallery(value.split('#').map(it => it.getFullUrl()));
                      });
                    }
                  }
                },
                {
                  targets: [columnNo++], data: 'passport', orderable: false,
                  createdCell: (td, value) => {
                    if (value) {
                      $(td).image_lightbox(value, () => {
                        context.showGallery(value.split('#').map(it => it.getFullUrl()));
                      });
                    }
                  }
                },
              ],
              order: [[1, 'DESC']],
              ajax: {
                url: 'room/user_list',
                data: function (req) {
                  req.room_id = room_id;
                }
              },
              drawCallback: function () {
              },
              fnCreatedRow: function (row, data, dataInd) {
              }
            }}>
            <thead>
            <tr>
              <th>{'이름'}</th>
              <th>{'직업'}</th>
              <th>{'주소'}</th>
              <th>{'전화번호'}</th>
              <th>{'본인사진'}</th>
              <th>{'여권사진'}</th>
            </tr>
            </thead>
            <tbody />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default RoomDetailMember;
