import React from 'react';
import { Link } from 'react-router-dom';
import DropdownProfile from './dropdown/profile.jsx';

import { PageSettings } from '../../config/page-settings';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <PageSettings.Consumer>
        {({ toggleMobileSidebar, pageSidebar }) => (
          <div id="header" className="header navbar-default">
            <div className="navbar-header">
              <Link to="/" className="navbar-brand">
                {/* <img className="m-r-4" src={'/assets/img/logo-title.png'}/>  */}
                관리자
              </Link>

              {pageSidebar && (
                <button type="button" className="navbar-toggle" onClick={toggleMobileSidebar}>
                  <span className="icon-bar"/>
                  <span className="icon-bar"/>
                  <span className="icon-bar"/>
                </button>
              )}
            </div>

            <ul className="navbar-nav navbar-right">
              <DropdownProfile/>
            </ul>
          </div>
        )}
      </PageSettings.Consumer>
    )
  }
}

export default Header;
