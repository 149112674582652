import { useContext, useEffect, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import strings from '../../../lang/strings';

const CheckInStaFragment = () => {

  const context = useContext(PageSettings);

  const [data, setData] = useState(null);
  useEffect(() => {
    context.get(
      'dashboard/checkin_sta',
      {},
      response => setData(response)
    );
  }, []);

  return (
    <div>
      <div className="widget widget-stats bg-blue height-150">
        <div className="d-flex align-items-center text-white m-t-5">
          <div className="d-flex w-100">
            <div>{strings.dashboard.today} {'체크인한 룸 개수'}</div>
            <div className="ml-auto">
              {data ? `${strings.dashboard.total + ' : ' + data.today?.currencyFormat()}` : `-`}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center text-white m-t-5">
          <div className="d-flex w-100">
            <div>{strings.dashboard.this_week} {'체크인한 룸 개수'}</div>
            <div className="ml-auto">
              {data ? `${strings.dashboard.total + ' : ' + data.this_week?.currencyFormat()}` : `-`}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center text-white m-t-5">
          <div className="d-flex w-100">
            <div>{strings.dashboard.this_month} {'체크인한 룸 개수'}</div>
            <div className="ml-auto">
              {data ? `${strings.dashboard.total + ' : ' + data.this_month?.currencyFormat()}` : `-`}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center text-white m-t-5">
          <div className="d-flex w-100">
            <div>{strings.dashboard.this_year} {'체크인한 룸 개수'}</div>
            <div className="ml-auto">
              {data ? `${strings.dashboard.total + ' : ' + data.this_year?.currencyFormat()}` : `-`}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center text-white m-t-5">
          <div className="d-flex w-100">
            <div>{strings.common.total} {'체크인한 룸 개수'}</div>
            <div className="ml-auto">
              {data ? `${strings.dashboard.total + ' : ' + data.total?.currencyFormat()}` : `-`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckInStaFragment;
