import React from 'react';
import ReactLoading from 'react-loading';

const LoadingBar = React.memo(() => (
  <div style={{
    display: 'block',
    position: 'fixed',
    left: '0',
    width: '100%',
    top: '0',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.2)',
    fontSize: '3em',
    textAlign: 'center',
    zIndex: '30000',
  }}>
    <ReactLoading type='spinningBubbles' color='#000000' className='loading'/>
  </div>
));

export default LoadingBar;
