import React from 'react';


const SearchSelect = React.memo(
  ({
     id = '',
     className = '',
     value = '',
     data = [],
     containerStyle = {},
     onChange
   }) => {

    const onSelect = e => {
      onChange?.(e.target.value);
    }

    return (
      <div className='form-horizontal form-inline' style={containerStyle}>
        <select id={id} className={'form-control ' + className} onChange={onSelect} defaultValue={value}
                style={{appearance: "auto"}}>
          {data.map(({value, name}, index) => (
            <option value={value} key={index}>{name}</option>
          ))}
        </select>
      </div>
    )
  }
);

export default SearchSelect;
