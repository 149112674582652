import { useContext, useEffect, useState } from "react";

import { Panel, PanelBody, PanelFooter, PanelHeader } from '../../../components/panel/panel.jsx';
import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import { Link } from "react-router-dom";
import QRCode from "react-qr-code";

const RoomDetailInfo = ({ room_id }) => {

  const context = useContext(PageSettings);

  const [room, setRoom] = useState({ other: '' });
  const [qrcodedata, setQRCodeData] = useState('');

  useEffect(() => {
    if (room_id > 0) {
      getRoomInfo()
    }
  }, [room_id]);

  const getRoomInfo = () => {
    context.get(
      '/room/detail/' + room_id,
      {},
      response => {
        setRoom(response);
      }
    );
  };

  const onRoomChange = e => {
    // if (e.target.id == 'roomNo') {
    //   // 룸번호를 수정하는 경우 qrcode 생성용 jwt token을 변경
    //   generateJWTToken(e.target.value);
    // }
    setRoom(prev => {
      return {
        ...prev,
        [e.target.id]: e.target.value
      }
    });
  }

  const onGenerateQR = () => {
    context.get(
      '/room/qr/' + room_id,
      {},
      response => {
        setQRCodeData(response.data);
      }
    );
  }

  const onQRDownload = () => {
    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  const onSave = () => {
    if (room.roomNo == undefined || room.roomNo == null) {
      context.showAlert('룸 번호를 입력하세요');
      return;
    }

    if (room.wifi == undefined || room.wifi == null) {
      context.showAlert('Wifi 정보를 입력하세요');
      return;
    }

    if (room.guide == undefined || room.guide == null) {
      context.showAlert('이용가이드를 입력하세요');
      return;
    }

    context.showConfirm(strings.modal.save_confirm, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'room/save/' + room_id,
        {
          roomNo: room.roomNo,
          wifi: room.wifi,
          guide: room.guide,
          other: room.other,
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.saved);
        }
      );
    });
  }

  return (
    <div>
      <Panel>
        <PanelHeader noButton={true}>{room_id == 0 ? strings.room.add : strings.room.detail}</PanelHeader>
        <PanelBody>
          <div className="row">
            <div className="col-xs-12">
              <div className='row'>
                <label className='col-xs-4 col-form-label'>{strings.room.room_no}</label>
                <div className="col-xs-8">
                  <input type='text' autoComplete='off' className='form-control' id="roomNo"
                    onChange={onRoomChange}
                    value={room?.roomNo || ''} />
                </div>
              </div>
              {
                room_id > 0 &&
                <div className='row'>
                  <label className='col-xs-4 col-form-label'>{''}</label>
                  <div className="col-xs-8 d-flex flex-column">
                    <div style={{ height: "auto", margin: "0 auto", maxWidth: 164, width: "100%" }}>
                      <QRCode
                        id="QRCode"
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={qrcodedata}
                        viewBox={`0 0 256 256`}
                      />
                    </div>
                    <div className="d-flex align-items-center justify-content-center m-t-10">
                      <button type='button' className='btn btn-default btn-sm' style={{width: 100}} onClick={() => {
                        onGenerateQR()
                      }}>{'QR 생성'}</button>
                      <button type='button' className='btn btn-primary btn-sm m-l-10' style={{width: 100}} onClick={() => {
                        onQRDownload()
                      }}>{'QR 다운로드'}</button>
                    </div>
                  </div>
                </div>
              }
              <div className='row m-t-5'>
                <label className='col-xs-4 col-form-label'>{strings.room.wifi}</label>
                <div className="col-xs-8">
                  <input type='text' autoComplete='off' className='form-control' id="wifi"
                    onChange={onRoomChange}
                    value={room?.wifi || ''} />
                </div>
              </div>
              <div className='row m-t-5'>
                <label className='col-xs-4 col-form-label'>{strings.room.guide}</label>
                <div className="col-xs-8">
                  <textarea className='form-control' id="guide" value={room?.guide || ''} required
                    rows={8} onChange={onRoomChange}
                  />
                </div>
              </div>
              {/* <div className='row'>
                <label className='col-xs-4 col-form-label'>{strings.room.image}</label>
                <div className='col-xs-8'>
                  {context.getSettingList('room_images')?.map(file => (
                    <img
                      key={file}
                      src={file.getFullUrl()}
                      style={{
                        width: 'auto',
                        height: 80,
                        cursor: 'pointer',
                        boxSizing: 'content-box',
                        marginRight: 10,
                        marginTop: 10,
                        borderWidth: (room?.image && file.includes(room?.image)) ? 4 : 0,
                        borderColor: 'gray',
                        borderStyle: 'solid'
                      }}
                      onClick={() => onRoomChange({ target: { id: 'image', value: file } })} />
                  ))}
                </div>
              </div> */}
              <div className='row m-t-5'>
                <label className='col-xs-4 col-form-label'>{strings.room.other}</label>
                <div className="col-xs-8">
                  <textarea className='form-control' id="other" value={room?.other || ''}
                    rows={8} onChange={onRoomChange} />
                </div>
              </div>
              {
                room_id > 0 &&
                <div className='row'>
                  <label className='col-xs-4 col-form-label'>{strings.common.create_date}</label>
                  <label className='col-xs-8 col-form-label'>{room?.createDt || ''}</label>
                </div>
              }
            </div>
          </div>
        </PanelBody>
        <PanelFooter className={"text-right"}>
          <div className="d-flex w-100">
            <div>
              <Link to="/room/list">
                <button type='button' className='btn btn-dark btn-sm m-2'>{strings.common.list}</button>
              </Link>
            </div>
            <div className="ml-auto">
              <button type='button' className='btn btn-success btn-sm m-2' onClick={() => {
                onSave()
              }}>{strings.common.save}</button>
            </div>
          </div>
        </PanelFooter>
      </Panel>
    </div>
  );
};

export default RoomDetailInfo;
