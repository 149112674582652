import UserStaFragment from './fragment/user-sta-fragment';
import CheckInStaFragment from './fragment/checkin-sta-fragment';
import strings from "../../lang/strings";

const Dashboard = () => {
  return (
    <div>
      <h1 className="page-header">{strings.menu.dashboard}</h1>
      <div className="row">
        <div className="col-xl-3 col-md-6">
          <CheckInStaFragment />
        </div>
        <div className="col-xl-3 col-md-6">
          <UserStaFragment />
        </div>
      </div>
    </div>
  );

}

export default Dashboard;
