import { inject } from 'mobx-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { PageSettings } from '../../config/page-settings';
import strings from '../../lang/strings';

const Login = ({ rootStore, history }) => {
  const context = useContext(PageSettings);
  const idRef = useRef();

  const [id, setId] = useState('');
  const [pwd, setPwd] = useState('');

  useEffect(() => {
    context.handleSetPageHeader(false);
    context.handleSetPageSidebar(false);

    idRef?.current?.focus();

    return () => {
      context.handleSetPageHeader(true);
      context.handleSetPageSidebar(true);
    }
  }, []);

  const handleSubmit = e => {
    e.preventDefault();

    context.post(
      'auth/login',
      {
        user_id: id,
        password: pwd
      },
      response => {
        // login 성공에 따른 처리를 진행한다.
        rootStore.signIn(response);
        history.push('/dashboard');
      }
    );
  }

  return (
    <React.Fragment>
      <div className="login-cover">
        <div className="login-cover-image"
             style={{ backgroundImage: 'url(/assets/img/login-bg.jpg)' }} />
        <div className="login-cover-bg" />
      </div>

      <div className="login login-v2">
        <div className="login-header">
          <div className="brand">
            <img className="" src={'./assets/img/logo.png'} /> {strings.admin}
          </div>
          <div className="icon hide">
            <i className="fa fa-lock" />
          </div>
        </div>
        <div className="login-content">
          <form className="margin-bottom-0" onSubmit={handleSubmit}>
            <div className="form-group m-b-20">
              <input type="text"
                     ref={idRef}
                     autoComplete={'off'}
                     className="form-control form-control-lg"
                     placeholder={strings.login.id}
                     value={id}
                     onChange={e => setId(e.target.value)}
                     required />
            </div>
            <div className="form-group m-b-20">
              <input type="password"
                     className="form-control form-control-lg"
                     placeholder={strings.login.password}
                     value={pwd}
                     onChange={e => setPwd(e.target.value)}
                     required />
            </div>
            <div className="login-buttons">
              <button type="submit" className="btn btn-success btn-block btn-lg">{strings.login.login}</button>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default withRouter(inject('rootStore')(Login));
