import React, { useContext, useEffect, useRef, useState } from "react";
import $ from "jquery";

import { PageSettings } from "../../config/page-settings";
import strings from "../../lang/strings";
import C from "../../config/const";
import SearchSelect from "../../components/control/search-select";
import SearchInput from "../../components/control/search-input";
import DataTable from "../../components/control/data-table";
import moment from "moment";
import { Panel, PanelBody, PanelHeader } from "../../components/panel/panel";
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";

const RoomList = ({ history }) => {

  const context = useContext(PageSettings);
  const searchOptions = strings.search_option.room;

  const [type, setType] = useState(1);
  const typeRef = useRef(type);

  useEffect(() => {
    typeRef.current = type;
    refreshTable(true);
  }, [type])

  const showRoomDetail = (id) => {
    history.push(`/room/detail/${id}`);
  }

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const onCell = user_id => {
    context.showUserPopup(user_id, () => refreshTable(false));
  };

  const onDelete = room_id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'room/' + room_id,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  };

  return (
    <Panel>
      <PanelHeader noButton={true}>{strings.menu.room}</PanelHeader>
      <PanelBody>
        <div className='col-md-12 m-t-20'>
          <div className='form-horizontal form-inline'>
            <SearchSelect
              id='searchColumn'
              value={searchOptions[0].value}
              data={searchOptions}
              onChange={() => refreshTable(true)} />
            <SearchInput
              id='searchInput'
              action={() => refreshTable(true)} />
            <button type='button' className='btn btn-success btn-sm ml-md-2'
              onClick={() => refreshTable(true)}>{strings.common.search}</button>
            <button type='button' className='btn btn-primary btn-sm ml-auto'
              onClick={() => {showRoomDetail(0)}}>{strings.common.add}</button>
          </div>
        </div>
        <div className='col-md-12 mt-3'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [{
                targets: [columnNo++], data: 'idx', orderable: false,
              }, {
                targets: [columnNo++], data: 'roomNo', orderable: false,
                createdCell: (td, value, item) => {
                  $(td)
                    .html(`<a href="javascript:;">${value}</a>`)
                    .find('a')
                    .on('click', () => showRoomDetail(item.idx));
                }
              }, {
                targets: [columnNo++], data: 'wifi', orderable: false,
              // }, {
              //   targets: [columnNo++], data: 'image', orderable: false,
              //   createdCell: (td, value) => {
              //     $(td).image_lightbox(value, () => {
              //       context.showGallery(value.split('#').map(it => it.getFullUrl()));
              //     });
              //   }
              }, {
                targets: [columnNo++], data: 'guide', orderable: false,
              }, {
                targets: [columnNo++], data: 'other', orderable: false,
              }, {
                targets: [columnNo++], data: 'createDt', orderable: false,
                render: value => moment(value).format(C.FORMAT.DATE)
              }, {
                targets: [columnNo++], data: 'idx', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).html(`<button type='button' class='btn btn-outline-danger btn-sm m-2 text-nowrap'>${strings.common.db_delete}</button>`)
                    .find('button:eq(0)')
                    .on('click', () => onDelete(item.id));
                }
              }],
              order: [[1, 'DESC']],
              ajax: {
                url: 'room/list',
                data: (req) => {
                  req.column = $('#searchColumn').val();
                  req.keyword = $('#searchInput').val();
                  req.type = typeRef.current;
                }
              },
            }}>
            <thead>
              <tr>
                <th>#</th>
                <th>{strings.room.room_no}</th>
                <th>{strings.room.wifi}</th>
                <th>{strings.room.guide}</th>
                {/* <th>{strings.room.image}</th> */}
                <th>{strings.room.other}</th>
                <th>{strings.common.create_date}</th>
                <th>{strings.common.manage}</th>
              </tr>
            </thead>
            <tbody />
          </DataTable>
        </div>
      </PanelBody>
    </Panel>
  );

}

export default withRouter(inject('rootStore')(RoomList));
