import React from "react";
import strings from "../lang/strings";

import Login from "../pages/auth/login";
import Dashboard from "../pages/dashboard/dashboard";
import RoomList from "../pages/room/room-list";
import ChangePassword from "../pages/manager/change_password";
import RoomDetail from "../pages/room/room-detail";

export const Extra = [
  {
    path: '/login', title: strings.login.login, exact: true, private: false,
    component: () => <Login />
  },
  {
    path: '/manager/change_pwd', title: strings.menu.change_password, exact: true, private: true,
    component: () => <ChangePassword />
  },
  {
    path: '/room/detail/:roomId', title: strings.menu.room_detail, exact: true, private: true,
    component: (props) => <RoomDetail room_id={props.match.params.roomId} />
  },
];

const Menu = [
  {
    path: '/dashboard', icon: 'fa fa-th', title: strings.menu.dashboard, exact: true, private: true,
    component: () => <Dashboard />
  },
  {
    path: '/room', icon: 'fa fa-coffee', title: strings.menu.room,
    children: [
      {
        path: '/room/list', title: strings.menu.room_all, exact: true, private: true,
        component: () => <RoomList/>
      },
    ]
  },
  {
    path: '/manager', icon: 'fa fa-user-secret', title: strings.menu.manager,
    children: [
      {
        path: '/manager/change_password', title: strings.menu.change_password, exact: true, private: true,
        component: () => <ChangePassword />
      },
    ],
    middle_manage: false,
  },
]

export default Menu;
