import React from 'react';
import { Panel, PanelBody, PanelHeader } from "../panel/panel";

const Container = props => {
  return (
    <Panel>
      <PanelHeader noButton={true} rightButton={props.rightButton}>{props.title}</PanelHeader>
      <PanelBody>
        {props.children}
      </PanelBody>
    </Panel>
  );
}

export default Container;
