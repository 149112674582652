import React, { useContext, useState } from 'react';
import strings from '../../lang/strings.js';
import Container from './../../components/control/container';
import { PageSettings } from "../../config/page-settings";

const ChangePassword = () => {
  const context = useContext(PageSettings);

  const [curPwd, setCurPwd] = useState('');
  const [newPwd, setNewPwd] = useState('');
  const [pwdConfirm, setPwdConfirm] = useState('');

  const handleSubmit = e => {
    e.preventDefault();

    if (newPwd !== pwdConfirm) {
      context.showAlert(strings.change_password.password_not_correct);
      return;
    }

    context.post(
      'manager/change_pwd',
      {
        cur_pwd: curPwd,
        new_pwd: newPwd
      },
      () => {
        context.addNotification(strings.modal.alert, strings.msg.applied);
      }
    );
  }

  return (
    <Container title={strings.menu.change_password}>
      <div className='col-md-6 table-bordered pr-0 pl-0'>
        <form id='frmPwd' className='form-horizontal form-bordered' onSubmit={handleSubmit}>
          <div className='row form-group'>
            <label className='col-md-4 text-right col-form-label'>{strings.change_password.current_password}</label>
            <div className='col-md-8'>
              <input type='password' className='form-control' value={curPwd} autoComplete='off' required
                     onChange={e => setCurPwd(e.target.value)} />
            </div>
          </div>
          <div className='row form-group'>
            <label className='col-md-4 text-right col-form-label'>{strings.change_password.new_password}</label>
            <div className='col-md-8'>
              <input type='password' className='form-control' value={newPwd} autoComplete='off' required
                     onChange={e => setNewPwd(e.target.value)} />
            </div>
          </div>
          <div className='row form-group'>
            <label className='col-md-4 text-right col-form-label'>{strings.change_password.confirm_password}</label>
            <div className='col-md-8'>
              <input type='password' className='form-control' value={pwdConfirm} autoComplete='off' required
                     onChange={e => setPwdConfirm(e.target.value)} />
            </div>
          </div>
          <div className='row form-group mt-4 mb-4 justify-content-center'>
            <button type='submit' className='btn btn-primary col-md-6'>{strings.change_password.change}</button>
          </div>
        </form>
      </div>
    </Container>
  );
};

export default ChangePassword;
