import { action, observable, set } from 'mobx';

export const rootStore = observable({

  me: null,
  setting: null,

  signIn: user => {
    rootStore.me = user;
    sessionStorage.setItem('admin', JSON.stringify(user));
  },

  signOut: () => {
    rootStore.me = null;
    sessionStorage.setItem('admin', null);
  },

  saveSetting: setting => {
    rootStore.setting = setting;
    sessionStorage.setItem('setting', JSON.stringify(setting));
  },

  get getSetting() {
    if (rootStore.setting == null) {
      try {
        rootStore.setting = JSON.parse(sessionStorage.getItem('setting'));
      } catch (e) {
        console.log(e);
      }
    }
    return rootStore.setting;
  },

  get getMe() {
    if (rootStore.me == null) {
      try {
        rootStore.me = JSON.parse(sessionStorage.getItem('admin'));
      } catch (e) {
        console.log(e);
      }
    }
    return rootStore.me;
  },

  get isSignedIn() {
    return rootStore.getMe !== null;
  },

  get token() {
    return rootStore.getMe?.token ?? '';
  },

  get nickname() {
    return rootStore.getMe?.nickname ?? '';
  },

}, {
  signIn: action,
  signOut: action,
  saveSetting: action
});
