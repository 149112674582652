import { useContext, useEffect, useState } from "react";

import { PageSettings } from "../../config/page-settings";
import strings from "../../lang/strings";
import { Panel, PanelBody, PanelFooter, PanelHeader } from "../../components/panel/panel";
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";
import RoomDetailInfo from "./fragment/room-detail-info";
import RoomDetailMember from "./fragment/room-detail-member";

const RoomDetail = ({ room_id, history }) => {

  const context = useContext(PageSettings);

  useEffect(() => {
  }, []);

  return (
    <div className="row">
      <div className="col-xl-6">
        <RoomDetailInfo room_id={room_id} onSave={() => {
          if (room_id < 1) {
            history.goBack();
          }
        }} />
      </div>
      {
        room_id > 0 &&
        <div className="col-xl-6">
          <Panel>
            <PanelHeader noButton={true}>{'체크인 유저'}</PanelHeader>
            <PanelBody>
              <RoomDetailMember room_id={room_id} />
            </PanelBody>
            <PanelFooter>
            </PanelFooter>
          </Panel>
        </div>
      }
    </div>
  );
};

export default withRouter(inject('rootStore')(RoomDetail));
