import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app.jsx';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { rootStore } from "./mobx/store";
import 'chart.js/auto';

// css
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-notifications-component/dist/theme.css';
import 'react-datetime/css/react-datetime.css';
import './assets/css/default/app.min.css';
import './scss/react.scss';
import './scss/custom.scss';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'react-image-lightbox/style.css';
// ========================================

// extension
import './helper/extension';
import 'moment/locale/ko';
// ========================================

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider rootStore={rootStore}>
      <App/>
    </Provider>
  </BrowserRouter>
);
